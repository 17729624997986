<template>
  <v-container class="tw-mb-10">
    <v-row>
      <v-col cols="12">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-9"
        >
          Edit Destination
        </h2>
      </v-col>
    </v-row>
    <v-form @submit.prevent="save">
      <v-row>
        <v-col>
          <j-text-field
            label="Destination"
            v-model="destination.name"
            placeholder="Enter Destination"
            background-color="#fafafafa"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider v-slot="{ errors }" rules="required|max:1000">
            <j-textarea
              outlined
              rounded
              background-color="#fafafa"
              :error-messages="errors"
              v-model="destination.text"
              label="Post Message*"
              sublabel="Tell the crew why they'd love this destination."
              placeholder="I've always wanted to go here! Plus the flights are cheap. What do you think..."
              class="tw-mb-6"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <p
            class="tw-text-xs tw-text-dark-green tw-text-left tw-font-semibold"
          >
            Image
          </p>
          <div class="tw-relative">
            <img
              src="@/assets/svgs/destinations/destination-placeholder-img.png"
              class="tw-w-full"
              alt="Destination"
            />
            <img
              src="@/assets/svgs/destinations/upload-destination-photo.svg"
              class="tw-absolute tw--bottom-2 tw--right-2 tw-cursor-pointer"
              alt="Upload Photo"
            />
          </div>
        </v-col>
      </v-row> -->
    </v-form>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "EditDestination",
  data() {
    return {
      destination: {
        name: null,
        tripId: this.$route.params.id,
        authorId: null,
        text: null,
        longitude: null,
        latitude: null,
        startDateTime: null,
        endDateTime: null
      }
    };
  },
  methods: {
    ...mapMutations(["meta/SET_NAVIGATION_BUTTON"]),
    setNavigationButton(options) {
      this["meta/SET_NAVIGATION_BUTTON"](options);
    },
    async save() {
      this.setNavigationButton({ text: "Saving" });
      try {
        await this.$store.dispatch("post-destination/patch", [
          this.$route.params.uuid,
          {
            name: this.destination.name,
            text: this.destination.text
          }
        ]);
        setTimeout(() => {
          this.setNavigationButton({ text: "Save" });
          this.$router.push({
            name: "DestinationsRoute",
            params: { id: this.$route.params.id }
          });
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    }
  },
  async beforeMount() {
    const destination = await this.$store.dispatch("post-destination/find", {
      query: {
        uuid: this.$route.params.uuid
      }
    });
    this.destination = destination.data[0];
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Destination",
      bgColor: "#0036F5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });
    this.setNavigationButton({
      button: true,
      text: "Save",
      isDisabled: false,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  }
};
</script>
